import { Container } from "@chakra-ui/react";
import * as React from "react";
import Footer from "../components/footer";
import Layout from "../components/layout";
import Navbar from "../components/navbar";
import Privacy from "../components/privacy";
import Seo from "../components/seo";
import { translations } from "../translations";
import type { Translation } from "../types/main";

const t: Translation = {
	language: "en",
	translations: translations.en,
	singleLanguage: true,
};

export default function PrivacyPolicy() {
	return (
		<Layout>
			<Seo title="Flowby - Cut your next line" />
			<Navbar t={t} />
			<Container maxW="5xl" py={{ base: 12, md: 24 }}>
				<Privacy />
			</Container>
			<Container maxW="8xl" py={12}>
				<Footer t={t} />
			</Container>
		</Layout>
	);
}
